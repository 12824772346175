import React, { SVGProps } from 'react';
// import CartIcon from '../images/CareEco_Color_Favicon.png';
import img from '../../images/CareEco_Color_Favicon.png';

export default function TwilioLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <img
      className="newcareecologo"
      style={{ position: 'absolute', width: '7%', top: '15px', left: '15px' }}
      src={img}
    />
    // <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 100 100" {...props}>
    //   <g id="Aptryt" transform="translate(-33.441 0.019)">
    //     <path
    //       id="Union_2"
    //       data-name="Union 2"
    //       d="M18.25,67.474c1.066-.382,2.072-.728,3.066-1.091A70.893,70.893,0,0,0,35.6,59.366,44.593,44.593,0,0,0,47.6,48.115a40.585,40.585,0,0,0,5.888-12.3,59.723,59.723,0,0,0,1.987-9.613c.192-1.526.29-3.053.436-4.579a23.359,23.359,0,0,1,1.27-5.964,10.945,10.945,0,0,1,3.528-4.853c.205-.159.423-.3.631-.436,11.955,11.17,15.459,32,3.489,47.642A35.7,35.7,0,0,1,36.119,72.251,34.827,34.827,0,0,1,18.25,67.474ZM7.168,70.191c.679-.471,1.321-.82,1.945-1.2s1.287-.772,1.928-1.16,1.283-.777,1.922-1.169,1.267-.785,1.932-1.2c.972.752,1.966,1.341,3.005,2.006-.273.1-.447.164-.628.218q-4.333,1.381-8.756,2.449a3.068,3.068,0,0,1-1.347.055Zm7.679-4.765h0l.047.037h0ZM.025,34.188C.972,21.247,7.178,11.527,18.166,4.682a15.066,15.066,0,0,0-3.837-1.565l.849-.367a7.172,7.172,0,0,1,3.673-.6A4.574,4.574,0,0,0,20.1,2.061a4.877,4.877,0,0,1,2.6.374,47.37,47.37,0,0,0-4.5,2.236c.081.094.113.158.167.188A26.484,26.484,0,0,1,23.417,8.9a57.4,57.4,0,0,1,15,24.143,20.4,20.4,0,0,1,1,6.591,13.017,13.017,0,0,1-2.041,6.928,30.066,30.066,0,0,1-3.834,4.694,69.546,69.546,0,0,1-11.018,9.137c-2.348,1.6-4.754,3.119-7.131,4.674l-.541.358C4.667,57.455-.408,47.125.025,34.188ZM43.133,20.541a3.609,3.609,0,0,1-2.51-1.352q-1.42-1.747-2.787-3.531A81.006,81.006,0,0,0,32.094,8.68a29.049,29.049,0,0,0-5.016-4.329,17.548,17.548,0,0,0-3.387-1.745,1.168,1.168,0,0,1-.371-.218A34.99,34.99,0,0,1,39.443.167,35.871,35.871,0,0,1,59.906,8.957a1.843,1.843,0,0,1-.233.176A23.43,23.43,0,0,0,53.784,13.6c-1.461,1.422-2.933,2.835-4.444,4.2a11.448,11.448,0,0,1-3.952,2.4,5.192,5.192,0,0,1-1.877.351Q43.322,20.554,43.133,20.541ZM39.64,10.166a5.322,5.322,0,0,0-.377,5.987,4.225,4.225,0,0,0,2.82,2.074c.414.1.85.131,1.287.194a5.054,5.054,0,0,0,5.1-6.268A4.937,4.937,0,0,0,44.582,8.4a5.363,5.363,0,0,0-1.165-.133A4.824,4.824,0,0,0,39.64,10.166ZM60.18,9.085a6.172,6.172,0,0,1,4.859-.393c-1.165.674-2.482.873-3.633,1.527Z"
    //       transform="translate(33.441 -0.019)"
    //       fill="#2B3278"
    //     />
    //   </g>
    // </svg>
  );
}
