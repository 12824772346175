import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { Client } from '@twilio/conversations';
import { Conversation } from '@twilio/conversations/lib/conversation';
import { Message } from '@twilio/conversations/lib/message';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  connect: (token: string) => void;
  hasUnreadMessages: boolean;
  messages: Message[];
  conversation: Conversation | null;
};

export const ChatContext = createContext<ChatContextType>(null!);

export const ChatProvider: React.FC = ({ children }) => {
  const { room, onError } = useVideoContext();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [chatClient, setChatClient] = useState<any>();

  const ChatAPI = require('twilio-chat');
  const [channel, setChannel] = useState<any>(null);

  const connect = useCallback(
    (token: string) => {
      ChatAPI.Client.create(token)
        .then((client: any) => {
          //@ts-ignore
          window.chatClient = client;

          // client.getSubscribedConversations().then(createOrJoinGeneralChannel);
          setChatClient(client);
        })
        .catch(() => {
          onError(new Error('There was a problem connecting to CareEco conversation service.'));
        });
    },
    [onError]
  );

  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = (message: Message) => setMessages(oldMessages => [...oldMessages, message]);
      conversation.getMessages().then(newMessages => setMessages(newMessages.items));
      conversation.on('messageAdded', handleMessageAdded);
      return () => {
        conversation.off('messageAdded', handleMessageAdded);
      };
    }
  }, [conversation]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  const joinChannel = async (channel: any) => {
    if (channel.channelState.status !== 'joined') {
      await channel.join();
    }
    setChannel(channel);
  };

  useEffect(() => {
    if (room && chatClient) {
      // chatClient.getSubscribedConversations().then(data => {
      //   console.log(data)
      // });

      chatClient
        .getChannelByUniqueName(room.name)
        .then((channel: any) => {
          console.log(channel);
          setConversation(channel);

          joinChannel(channel);
          setChannel(channel);
        })
        .catch(() => {
          chatClient
            .createChannel({
              uniqueName: room.name,
              friendlyName: room.localParticipant.identity,
            })
            .then((channel: any) => {
              setConversation(channel);
              joinChannel(channel);
            });
        })
        .catch(() => {
          throw new Error('Unable to create channel, please reload this page');
        });
      // }

      // chatClient
      //   .getConversationByUniqueName(room.name)
      //   .then(newConversation => {
      //     //@ts-ignore
      //     window.chatConversation = newConversation;
      //     setConversation(newConversation);
      //     newConversation.join().then(con => {
      //       //
      //     });
      //   })
      //   .catch(() => {
      //     // Math.floor(100000 + Math.random() * 900000)
      //     chatClient.createConversation({
      //       uniqueName: `${room.name}`,
      //       friendlyName: `${room.localParticipant.identity}`
      //     })
      //       .then((conversation) => {
      //         // newConversation created, set up the newConversation now
      //         //@ts-ignore
      //         window.chatConversation = conversation;
      //         setConversation(conversation);
      //         conversation.join().then(con => {
      //           //
      //         });
      //       })
      //       .catch(() => {
      //         onError(new Error('There was a problem getting the Conversation associated with this room.'));
      //       });

      //   });
    }
  }, [room, chatClient, onError]);

  return (
    <ChatContext.Provider
      value={{ isChatWindowOpen, setIsChatWindowOpen, connect, hasUnreadMessages, messages, conversation }}
    >
      {children}
    </ChatContext.Provider>
  );
};
